import React, { useState } from "react";
import useFetchIncidents from "../../hooks/FetchIncidents";
import Message from "../../types/Message";
import "./style.css";

const AdminDashboard: React.FC = () => {
  const [messages, setMessages]: [
    Message[],
    React.Dispatch<React.SetStateAction<Message[]>>
  ] = useFetchIncidents(-1);
  const [newMessage, setNewMessage] = useState<string>("");
  const [newLocation, setNewLocation] = useState<string>("");

  const handlePostMessage = () => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/incidents`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: newMessage, location: newLocation }),
    })
      .then((response: Response) => response.json())
      .then((data) => setMessages([...messages, data.message]))
      .catch((error: any) => console.error("Error posting message:", error));
  };

  const handleDeleteMessage = (id: number) => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/incidents/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response: Response) => response.json())
      .then((_) => setMessages(messages.filter((message) => message.id !== id)))
      .catch((error: any) => console.error("Error deleting message:", error));
  };

  const locationMessages = () => {
    return (
      <>
        <h2 className="admin-dashboard-header">Existing Status Messages</h2>
        <div className="admin-dashboard-location-messages-wrapper">
          <ul>
            {Array.from(
              new Set(messages.map((message) => message.location))
            ).map((location, index) => (
              <>
                <li key={index}>
                  <h2 className="admin-dashboard-location-header">
                    {location}
                  </h2>
                  {messages
                    .filter((message) => message.location === location)
                    .map((message) => (
                      <table>
                        <tr>
                          <td>
                            <span>{message.message}</span>
                          </td>
                          <td>
                            <button
                              className="admin-dashboard-button"
                              onClick={() => handleDeleteMessage(message.id)}
                            >
                              Delete Message
                            </button>
                          </td>
                        </tr>
                      </table>
                    ))}
                </li>
              </>
            ))}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <h1 className="admin-dashboard-title">Admin Dashboard</h1>
      <div className="admin-dashboard-wrapper">
        <h2 className="admin-dashboard-header">Post New Message</h2>
        <label htmlFor="newMessage">Message</label>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />

        <label htmlFor="newLocation">Location</label>
        <input
          type="text"
          value={newLocation}
          onChange={(e) => setNewLocation(e.target.value)}
        />
        <button
          className="admin-dashboard-button"
          onClick={() => {
            handlePostMessage();
            setNewMessage("");
            setNewLocation("");
          }}
        >
          Post Message
        </button>
        {locationMessages()}
      </div>
    </>
  );
};

export default AdminDashboard;
