import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import CSRMarquee from "./components/CSRMarquee";
import CSRHeader from "./components/CSRHeader";
import AdminDashboard from "./pages/AdminDashboard";
import Homepage from "./pages/Homepage";

function App() {
  useEffect(() => {
    const script = document.createElement("script");

    script.id = "ze-snippet";
    script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=158bbbda-d4ad-43e2-a27f-c44469efc5cd";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <CSRHeader />
        <CSRMarquee />
        <Routes>
          <Route
            path="/"
            element={
              <Homepage />
            }
          />
          <Route path="/admin" element={<AdminDashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
