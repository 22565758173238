import "./style.css";
import Marquee from "react-fast-marquee";
import useFetchIncidents from "../../hooks/FetchIncidents";
import Message from "../../types/Message";

const CSRMarquee = () => {
  let messages: string[] = useFetchIncidents(300000)[0].map((message: Message) => message.message);

  if (messages.length === 0) {
    return null;
  } else {
    return (
      <Marquee className="csr-marquee" gradient={false} speed={120}>
        {messages.map((message, index) => (
          <h1 key={index}>{message}</h1>
        ))}
      </Marquee>
    );
  }
};

export default CSRMarquee;
