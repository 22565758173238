import React from "react";
import background from './../../assets/background.png';
import './style.css';

const Homepage: React.FC = () => {
  return (
    <div>
      <img src={background} className="csr-background" alt="main-background" />
    </div>
  );
};

export default Homepage;
