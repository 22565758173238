import { useState, useEffect } from 'react';
import Message from '../types/Message';

const useFetchIncidents = (fetchInterval: number): [Message[], React.Dispatch<React.SetStateAction<Message[]>>] => {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/api/v1/incidents`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setMessages(data.messages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // -1 means don't fetch data at an interval
    if (fetchInterval !== -1) {
      // Fetch data every 5 minutes
      const interval = setInterval(fetchData, fetchInterval);

      return () => clearInterval(interval);
    } else {
      return;
    }
  }, []);

  return [messages, setMessages];
};

export default useFetchIncidents;