import React from "react";
import "./style.css";
import hamburgerIcon from "../../assets/icons/hamburger.png";
import csrLogo from "../../assets/logo.png";

const CSRHeader: React.FC = () => {
  return (
    <header className="csr-header">
      <a href="">
        <img src={csrLogo} className="csr-logo" alt="logo" />
      </a>
      <a href="">
        <img src={hamburgerIcon} className="hamburger-icon" alt="menu" />
      </a>
    </header>
  );
};

export default CSRHeader;
